import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Archive = ({ pageContext, data }) => {
  return (
    <Layout wider>
      <Seo title={pageContext.data.title} />

      <section className="produkter-main">
        <div className="container-fluid wider">
          <h1>{pageContext.data.title}</h1>
          <div className="row">
            <div className="col-md-3">
              <div className="produkter-main-sidebar">
                <h2>Produktmeny</h2>
                {data?.allWpProdukter?.nodes
                  ?.filter(item => item.parentId === null)
                  .map((item, index) => (
                    <div key={index}>
                      <Link
                        to={item.uri}
                        className={
                          item.id === pageContext.data.data.id
                            ? "current-section"
                            : ""
                        }
                      >
                        <h4>{item.title}</h4>
                      </Link>
                      {item.wpChildren.nodes &&
                        item.id === pageContext.data.data.id && (
                          <ul>
                            {item.wpChildren.nodes.map(node => (
                              <li key={node.id}>
                                <Link to={node.uri}>{node.title}</Link>
                              </li>
                            ))}
                          </ul>
                        )}
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-md-9">
              <div className="produkter-main-content">
                <div className="row">
                  {pageContext?.data?.data?.wpChildren?.nodes?.map(
                    (item, index) => (
                      <div className="col-lg-4 col-6" key={index}>
                        <Link to={item.uri} className="produkt-tile">
                          <div
                            className="produkt-tile-thumb"
                            style={{
                              backgroundImage: `url(${item?.featuredImage?.node?.localFile?.publicURL})`,
                            }}
                          ></div>
                          <div className="produkt-tile-title">{item.title}</div>
                        </Link>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpProdukter(sort: { fields: title, order: ASC }) {
      nodes {
        id
        title
        uri
        parentId
        wpChildren {
          nodes {
            id
            uri
            ... on WpProdukter {
              id
              title
              content
              uri
              wpParent {
                node {
                  ... on WpProdukter {
                    id
                    title
                  }
                }
              }
            }
          }
        }
        wpParent {
          node {
            id
            uri
            ... on WpProdukter {
              id
              title
              content
              uri
              featuredImage {
                node {
                  localFile {
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Archive
